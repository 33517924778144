<template>
  <div class="flex items-center justify-center">
    <div class="text-center py-10">
      <h1>Service on Available</h1>
      <p>{{ service }} is temporarily unavailable. Please check back later.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotAvailable',
  data: () => ({
    service: ''
  }),
  created() {
    this.service = this.$route.params.service
  }
}
</script>

<style></style>
